import {Component, OnInit} from '@angular/core';
import {MocksService} from '../mocks/mocks.service';
import {RacesService} from '../../ranking/races/races.service';
import {Race} from '../../ranking/races/race';
import {ResultsService} from '../../ranking/results/results.service';
import {
  filter,
  mergeMap,
  map,
  tap,
  switchMap,
  toArray,
  withLatestFrom
} from 'rxjs/operators';
import {Observable, BehaviorSubject, from, combineLatest} from 'rxjs';
import {Result} from '../../ranking/results/result/result';
import {DriversService} from '../drivers/drivers.service';
import {Driver} from '../drivers/driver';
import * as moment from 'moment';
import {GateReadingsService} from '../gate-readings/gate-readings.service';
import {ResultsConfig} from '../../ranking/results/results-config/results-config';
import {StandingsConfig} from '../../ranking/standings/standings-config';
import {GateReading} from '../gate-readings/gate-reading';
import {PzGateReading} from './pz-gate-reading';
import {StandingsService} from '../../ranking/standings/standings.service';
import {Standing} from '../../ranking/standing';

@Component({
  selector: 'mc-backup-results-merge',
  templateUrl: './backup-results-merge.component.html',
  styleUrls: ['./backup-results-merge.component.scss']
})
export class BackupResultsMergeComponent implements OnInit {

  results: Result[] = [];

  race$: Observable<Race> = this.racesService.requestRaces().pipe(
    mergeMap(races => races),
    filter(race => race.id === 6)
  );

  standingsConfig$ = new BehaviorSubject<StandingsConfig>({});
  resultsConfig$ = new BehaviorSubject<ResultsConfig>({});

  standings$: Observable<Standing[]> = this.race$.pipe(
    switchMap((race: Race) => {
      this.resultsConfig$.next(race.resultsConfig);

      return combineLatest([
        from([race]),
        this.driversService.requestDriversCsv(race.driversCsvFile)
      ]);
    }),
    switchMap(([race, drivers]: [Race, Driver[]]) => {
      this.driversService.drivers = drivers;

      return combineLatest([
        this.mocksService.get('20220826/raw-results.txt').pipe(
          switchMap(content => {
            const json = content
              .split('\r\n')
              .filter(line => !!line)
              .map((line, i) => {
                let empty = {
                  antenna: undefined,
                  id: undefined,
                  internal_id: undefined,
                  key: undefined,
                  name: undefined,
                  tag_id: undefined,
                  timestamp: undefined
                } as PzGateReading;
                const elements = line.split(/,|\s+/);
                const tagIdElement = elements.find(element => element.match(/^tag_id=/));
                const tag_id = tagIdElement ? tagIdElement.replace(/^tag_id=/, '') : undefined;
                const timestampElement = elements.find(element => element.match(/^first=/));
                const timestamp = timestampElement ? timestampElement.replace(/^first=/, '') : undefined;
                return Object.assign({}, empty, {
                  internal_id: i,
                  tag_id,
                  timestamp
                });
              });
            return json;
          }),
          // switchMap(jsonContent => JSON.parse(jsonContent)),
          (stream: Observable<PzGateReading>) => this.pzGateReadingToGateReadingOperator(stream),
          toArray()
        ),
        // from(race.gateReadingsCsvFiles).pipe(
        //   mergeMap(gateReadingsCsvFile => this.gateReadingsService.requestGateReadings(gateReadingsCsvFile)),
        // )
      ]).pipe(
        mergeMap((gateReadingsCollections: GateReading[][]): Observable<GateReading> => GateReadingsService.concat.apply(null, gateReadingsCollections)),
        map((gateReading: GateReading): [GateReading, number] => [gateReading, 2]),
        (stream: Observable<[GateReading, number]>): Observable<GateReading> => this.gateReadingsService.filterThresholdOperator(stream),
        withLatestFrom([drivers], this.standingsConfig$, this.resultsConfig$),
        // tap(([gateReading, drivers, standingsConfig, resultsConfig]: [GateReading, Driver[], StandingsConfig, ResultsConfig]) => console.log(gateReading, drivers, standingsConfig, resultsConfig)),
        (stream: Observable<[GateReading, Driver[], StandingsConfig, ResultsConfig]>): Observable<Result> => this.gateReadingsService.mapToResultsOperator(stream),
        // filter((result: Result) => result.driverId === 91 && result.createdAt > '2019-05-11 11:47:00' && result.createdAt < '2019-05-11 11:49:00'),
        toArray(),
        withLatestFrom(this.resultsService.requestResults(race.resultsJsonFile)),
        map(([newResults, oldResults]) => {

          const mergedResultsIndex: { [key: string]: Result } = {};

          newResults.forEach(result => mergedResultsIndex[result.createdAt] = result);
          oldResults.forEach(result => {
            if (!mergedResultsIndex[result.createdAt]) {
              mergedResultsIndex[result.createdAt] = result;
            }
          });

          const results = Object.values(mergedResultsIndex);
          this.results = newResults;
          this.results = results;
          console.log(results);
          return results;
        }),
        mergeMap((results: Result[]): Observable<Standing[]> => from(this.standingsService.fromResults(results)).pipe(
          withLatestFrom(this.resultsConfig$),
          map(([standing, resultsConfig]: [Standing, ResultsConfig]): Standing => this.standingsService.getRecalculated(standing, resultsConfig)),
          toArray()
        ))
      );
    })
  );

  jsonExport = null;

  constructor(
    private racesService: RacesService,
    private resultsService: ResultsService,
    private mocksService: MocksService,
    private driversService: DriversService,
    private gateReadingsService: GateReadingsService,
    private standingsService: StandingsService
  ) {
  }

  ngOnInit() {
  }

  pzGateReadingToGateReadingOperator(stream: Observable<PzGateReading>): Observable<GateReading> {
    return stream.pipe(
      map((pzGateReading: PzGateReading) => {
        const createdAt = moment.utc(pzGateReading.timestamp).format('YYYY-MM-DD HH:mm:ss.SSS');
        return {
          gr_id: pzGateReading.internal_id + 10000000,
          gate_id: 20,
          tag_code: pzGateReading.tag_id.substr(6, 8),
          first_detection_date: createdAt,
          last_detection_date: createdAt,
          save_date: createdAt,
          status: 0,
          lap: null
        };
      })
    );
  }

  exportResults() {
    this.jsonExport = this.results;
  }
}
