<form novalidate #form="ngForm">
  <div class="form-row">
    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="group">Grid group color:</label>
      <select
        id="group"
        class="form-control form-control-sm"
        [value]="group"
        (change)="group = $event.target.value"
      >
        <option value="">All groups</option>
        <option value="1">Green</option>
        <option value="2">Red</option>
        <option value="3">Yellow</option>
        <option value="4">Blue</option>
        <option value="5">Black</option>
        <option value="6">White</option>
        <option value="7">Orange</option>
        <option value="8">Purple</option>
      </select>
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="category">Primary category:</label>
      <select
        id="category"
        class="form-control form-control-sm"
        [value]="category"
        (change)="category = $event.target.value"
      >
        <option value="">All categories</option>
        <option value="11">Do 2L</option>
        <option value="12">2WD do 2.5L</option>
        <option value="13">AWD do 2.5L</option>
        <option value="21">2WD do 3L</option>
        <option value="22">AWD do 3L</option>
        <option value="31">2WD powyżej 3L</option>
        <option value="32">AWD powyżej 3L</option>
        <option value="41">PRO do 2L</option>
        <option value="42">PRO 2WD do 2.5L</option>
        <option value="43">PRO 2WD do 3L</option>
        <option value="44">PRO 2WD 3L+ i AWD 2L+</option>
        <option value="51">OPEN</option>
        <option value="52">OPEN 2WD do 250 KM</option>
        <option value="53">OPEN 2WD 250+ KM</option>
        <option value="54">OPEN AWD</option>
      </select>
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="classic">Side category:</label>
      <select
        id="classic"
        class="form-control form-control-sm"
        [value]="classic"
        (change)="classic = $event.target.value"
      >
        <option value="">All side categories</option>
        <option value="1">Hot Classic</option>
        <option value="0">Without Hot Classic cars</option>
      </select>
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="women">Driver gender:</label>
      <select
        id="women"
        class="form-control form-control-sm"
        [value]="women"
        (change)="women = $event.target.value"
      >
        <option value="">All genders</option>
        <option value="1">Women only</option>
        <option value="0">Men only</option>
      </select>
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="street">V-Race cars:</label>
      <select
        id="street"
        class="form-control form-control-sm"
        [value]="street"
        (change)="street = $event.target.value"
      >
        <option value="">No V-Race filter</option>
        <option value="1">V-Race 450 only</option>
        <option value="2">V-Race 450+ only</option>
      </select>
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="pro">V-Race cars:</label>
      <select
        id="pro"
        class="form-control form-control-sm"
        [value]="pro"
        (change)="pro = $event.target.value"
      >
        <option value="">No V-Race filter</option>
        <option value="1">V-Race 450 only</option>
        <option value="2">V-Race 450+ only</option>
      </select>
    </div>
  </div>

  <div class="form-row">
    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="ranking-laps-number">Ranking laps number:</label>
      <input type="number"
             class="form-control form-control-sm"
             id="ranking-laps-number"
             name="rankingLapsNumber"
             [value]="rankingLapsNumber"
             (change)="rankingLapsNumber = $event.target.value"
      />
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="minimum-lap-seconds">Minimal laptime (s):</label>
      <input type="number"
             class="form-control form-control-sm"
             id="minimum-lap-seconds"
             name="minimumLapSeconds"
             [value]="minimumLapSeconds"
             (change)="minimumLapSeconds = $event.target.value"
      >
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="maximum-lap-seconds">Maximal laptime (s):</label>
      <input type="number"
             class="form-control form-control-sm"
             id="maximum-lap-seconds"
             name="maximumLapSeconds"
             [value]="maximumLapSeconds"
             (change)="maximumLapSeconds = $event.target.value"
      >
    </div>
  </div>
</form>
