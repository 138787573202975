import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MocksService } from '../mocks/mocks.service';
import { map, mergeMap, scan, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { GateReadingsService } from '../gate-readings/gate-readings.service';
import { ResultsService } from '../../ranking/results/results.service';
import {
  BehaviorSubject,
  combineLatest,
  from,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription
} from 'rxjs';
import { Driver } from '../drivers/driver';
import { GateReading } from '../gate-readings/gate-reading';
import { StandingsConfig } from '../../ranking/standings/standings-config';
import { ResultsConfig } from '../../ranking/results/results-config/results-config';
import { Result } from '../../ranking/results/result/result';
import { RankingsService } from '../../ranking/rankings.service';
import { Ranking } from '../../ranking/ranking';
import { RacesService } from '../../ranking/races/races.service';
import { Race } from '../../ranking/races/race';
import { StandingsService } from '../../ranking/standings/standings.service';
import { StandingsFilterPipe } from '../../ranking/standings/standings-filter.pipe';
import { DriversService } from '../drivers/drivers.service';
import { ResultsImport2Service } from './results-import2.service';
import { RemoteResultsService } from '../../ranking/results/remote-results.service';
import { Standing } from '../../ranking/standing';

@Component({
  selector: 'mc-results-import2',
  templateUrl: './results-import2.component.html',
  styleUrls: ['./results-import2.component.scss']
})
export class ResultsImport2Component implements OnInit, OnDestroy, AfterViewInit {

  readonly RFID_ACCURACY_THRESHOLD = 2;

  race: Race;
  rankings: Ranking[] = []; // Rankings of the race.
  standings: Standing[] = [];

  gateReadings: GateReading[] = [];

  drivers$ = new ReplaySubject<Driver[]>(1);
  driversFromCsv$ = new Subject<Driver[]>();
  standingsConfig$ = new BehaviorSubject<StandingsConfig>({});
  resultsConfig$ = new BehaviorSubject<ResultsConfig>({
    rankingLapsNumber: 3,
    minimumLapSeconds: 30,
    maximumLapSeconds: 360
  });

  gateReadings$ = new BehaviorSubject<GateReading[]>([]);

  resolveSubscription: Subscription;
  resultSubscription: Subscription;
  dbConnectionSubscription: Subscription;
  syncSubscription: Subscription;

  jsonExport = null;
  showLocalResultsPreview = false;

  resultsFromGateReadings$: Observable<Result[]> = combineLatest([
    this.drivers$,
    this.standingsConfig$,
    this.resultsConfig$
  ]).pipe(
    switchMap(([drivers, standingsConfig, resultsConfig]: [Driver[], StandingsConfig, ResultsConfig]) => this.gateReadings$.pipe(
      tap((gateReadings: GateReading[]) => this.gateReadings = gateReadings),
      mergeMap((gateReadings: GateReading[]) => from(gateReadings).pipe(
        map((gateReading: GateReading) => [gateReading, this.RFID_ACCURACY_THRESHOLD]),
        this.gateReadingsService.filterThresholdOperator,
        // withLatestFrom(this.resultsConfig$),
        // map(([gateReading, resultsConfig]: [GateReading, ResultsConfig]) => [gateReading, resultsConfig.minimumLapSeconds, resultsConfig.maximumLapSeconds]),
        // this.gateReadingsService.filterLaptimeDurationOperator,
        map(gateReading => [gateReading, drivers, standingsConfig, resultsConfig]),
        (stream: Observable<[GateReading, Driver[], StandingsConfig, ResultsConfig]>) => this.gateReadingsService.mapToResultsOperator(stream),
        toArray()
      ))
    ))
  );

  constructor(
    private mocks: MocksService,
    private gateReadingsService: GateReadingsService,
    public resultsService: ResultsService,
    private rankingsService: RankingsService,
    private racesService: RacesService,
    private standingsService: StandingsService,
    private standingsFilter: StandingsFilterPipe,
    private driversService: DriversService,
    private resultsImport2Service: ResultsImport2Service,
    public remoteResultsService: RemoteResultsService
  ) {
  }

  ngOnInit() {
    this.resolveSubscription = this.resultsImport2Service.loadRace().pipe(
      tap(([race, rankings, drivers$]) => {
        this.race = race;
        this.rankings = rankings;
      }),
      // tap(v => console.log(11, 'Loading race with rankings: ', v)),
      switchMap(([race, rankings, drivers$]) => merge(
        drivers$.pipe(
          takeUntil(this.driversFromCsv$)
        ),
        this.driversFromCsv$
      ).pipe(
        // tap(v => console.log(12, 'Loading drivers from Spreadsheet or CSV: ', v)),
        tap(drivers => this.driversService.drivers = drivers),
        tap(drivers => this.drivers$.next(drivers)),
        switchMap(drivers => this.resultsFromGateReadings$),
        // tap(v => console.log(13, 'Loading results from GateReadings: ', v)),
        tap(results => {
          const changed = this.resultsService.mergeToState(results);
          console.log('state: ' + this.resultsService.results.length + ', changed: ' + changed.length);
          this.recalculateRankings(this.standingsService.fromResults(this.resultsService.results));
          this.resultsService.broadcastChanges(changed);
          if (this.remoteResultsService.isSyncing()) {
            this.remoteResultsService.db.bulkDocs(changed);
          }
        })
      ))
    ).subscribe();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (this.resolveSubscription) {
      this.resolveSubscription.unsubscribe();
    }
    if (this.resultSubscription) {
      this.resultSubscription.unsubscribe();
    }
    if (this.syncSubscription) {
      this.syncSubscription.unsubscribe();
    }
    this.resultsService.replaceState([]);
  }

  trackRanking(index: number, ranking: Ranking) {
    return ranking.id;
  }

  // test(gateReadings, intervalSeconds) {
  //   const n = gateReadings.length;
  //   let i = 0;
  //   console.log('Test started...');
  //   const loop = setInterval(() => {
  //     if (i > n) {
  //       clearInterval(loop);
  //       console.log('Test finished.');
  //     } else {
  //       console.log('Test iteration: ' + i);
  //       this.gateReadings$.next(gateReadings.slice(0, i));
  //     }
  //     i++;
  //   }, intervalSeconds * 1000);
  // }

  // test2() {
  //   this.gateReadingsService.requestGateReadings('20210328/GateReadings-2021-03-28.csv').subscribe(grs => {
  //     const oneDriverGrs = grs.filter(gr => gr.tag_code === '3A420324');
  //
  //     for (let i = 0; i < grs.length && i < 300; i++) {
  //       // console.log(14, oneDriverGrs.slice(Math.max(0, i - 200 + 1), i + 1));
  //       this.updateGateReadings(oneDriverGrs.slice(Math.max(0, i - 200 + 1), i + 1));
  //     }
  //   });
  // }

  recalculateRankings(standings: Standing[]) {
    this.standings = standings.map(standing => this.standingsService.getRecalculated(standing, this.race.resultsConfig));
  }

  updateStandingsConfig(standingsConfig: StandingsConfig) {
    this.standingsConfig$.next(standingsConfig);
  }

  updateResultsConfig(resultsConfig: ResultsConfig) {
    this.resultsConfig$.next(resultsConfig);
  }

  updateGateReadings(gateReadings: GateReading[]) {
    this.gateReadings$.next(gateReadings);
  }

  connect(dbName: string) {
    this.dbConnectionSubscription = this.remoteResultsService.connect(dbName).pipe(
      mergeMap(() => this.refreshResults())
    ).subscribe(newResults => {
      console.log('Connected to ' + dbName + '.');
    });
  }

  disconnect() {
    this.dbConnectionSubscription.unsubscribe();
    return this.remoteResultsService.disconnect();
  }

  isConnected() {
    return this.remoteResultsService.isConnected();
  }

  refreshResults() {
    console.log('Refreshing results...');
    return this.remoteResultsService.findAll().pipe(
      tap(results => console.log('Refreshed results', results.length)),
      map(results => this.resultsService.mergeToState(results)),
      tap(changed => console.log('Refreshed changes', changed.length)),
      tap(changed => this.resultsService.broadcastChanges(changed))
    );
  }

  sync(toggle) {
    if (toggle) {
      console.log('Turning sync on...');
    } else {
      console.log('Turning sync off...');
    }
    if (this.syncSubscription) {
      this.syncSubscription.unsubscribe();
    }
    if (toggle) {
      this.syncSubscription = this.remoteResultsService.syncOn(true, true).pipe(
        // takeWhile(state => !state),
        mergeMap(state => toggle ? this.refreshResults() : [[]])
      ).subscribe(newResults => {
        if (toggle) {
          console.log('Sync is now on.');
          console.log('Pulled ' + newResults.length + ' results after sync.');
        } else {
          console.log('Sync is now off.');
        }
      });
    } else {
      this.remoteResultsService.syncOff();
      console.log('Sync is now off.');
    }
  }

  exportGateReadings() {
    this.jsonExport = this.gateReadings;
  }

  exportResults() {
    this.jsonExport = this.resultsService.results;
  }

  clearState() {
    this.resultsService.clearState();
    this.updateGateReadings([]);
  }
}
