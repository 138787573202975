import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StandingsConfig } from '../standings-config';
import { ResultsConfig } from '../../results/results-config/results-config';

@Component({
  selector: 'mc-standings-moderation',
  templateUrl: './standings-moderation.component.html',
  styleUrls: ['./standings-moderation.component.scss']
})
export class StandingsModerationComponent implements OnInit {

  @Output() standingsConfigChanged = new EventEmitter<StandingsConfig>();
  @Output() resultsConfigChanged = new EventEmitter<ResultsConfig>();

  protected standingsConfig: StandingsConfig = {} as StandingsConfig;

  protected resultsConfig: ResultsConfig = {
    minimumLapSeconds: 30,
    maximumLapSeconds: 360,
    rankingLapsNumber: 3
  };

  set group(group: string|'') {
    this.standingsConfig.group = group === '' ? undefined : +group;
    this.emitStandingsConfig();
  }

  get group(): string {
    return this.standingsConfig.group === undefined ? '' : '' + this.standingsConfig.group;
  }

  set category(category: string|'') {
    this.standingsConfig.category = category === '' ? undefined : +category;
    this.emitStandingsConfig();
  }

  get category(): string {
    return this.standingsConfig.category === undefined ? '' : '' + this.standingsConfig.category;
  }

  set classic(classic: string|'') {
    this.standingsConfig.classic = classic === '' ? undefined : !!+classic;
    this.emitStandingsConfig();
  }

  get classic(): string {
    return this.standingsConfig.classic === undefined ? '' : +this.standingsConfig.classic + '';
  }

  set street(street: string|'') {
    this.standingsConfig.street = street === '' ? undefined : !!+street;
    this.emitStandingsConfig();
  }

  get street(): string {
    return this.standingsConfig.street === undefined ? '' : +this.standingsConfig.street + '';
  }

  set pro(pro: string|'') {
    this.standingsConfig.pro = pro === '' ? undefined : !!+pro;
    this.emitStandingsConfig();
  }

  get pro(): string {
    return this.standingsConfig.pro === undefined ? '' : +this.standingsConfig.pro + '';
  }

  set women(women: string|'') {
    this.standingsConfig.women = women === '' ? undefined : !!+women;
    this.emitStandingsConfig();
  }

  get women(): string {
    return this.standingsConfig.women === undefined ? '' : +this.standingsConfig.women + '';
  }

  set yt(yt: string|'') {
    this.standingsConfig.yt = yt === '' ? undefined : !!+yt;
    this.emitStandingsConfig();
  }

  get yt(): string {
    return this.standingsConfig.yt === undefined ? '' : +this.standingsConfig.yt + '';
  }

  set minimumLapSeconds(n: number) {
    this.resultsConfig.minimumLapSeconds = +n;
    this.emitResultsConfig();
  }

  get minimumLapSeconds(): number {
    return this.resultsConfig.minimumLapSeconds;
  }

  set maximumLapSeconds(n: number) {
    this.resultsConfig.maximumLapSeconds = +n;
    this.emitResultsConfig();
  }

  get maximumLapSeconds(): number {
    return this.resultsConfig.maximumLapSeconds;
  }

  set rankingLapsNumber(n: number) {
    this.resultsConfig.rankingLapsNumber = +n;
    this.emitResultsConfig();
  }

  get rankingLapsNumber(): number {
    return this.resultsConfig.rankingLapsNumber;
  }

  constructor() { }

  ngOnInit() {
    this.emitStandingsConfig();
    this.emitResultsConfig();
  }

  emitStandingsConfig() {
    this.standingsConfigChanged.emit({... this.standingsConfig});
  }

  emitResultsConfig() {
    this.resultsConfigChanged.emit({... this.resultsConfig});
  }

}
